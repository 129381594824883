<template>
    <div class="ant-card" id="course_category">
        <a-spin v-if="$route.meta.isShow" :spinning="spinning" size="large">
            <div class="ant-card-body">
                <div class="head-box">
                    <div style="display: flex;align-items: center">
                        <span>用户当前归属：</span>
                        <TreeselectCode @treeDataChange="treeDataChange" :code="code" :isDateShow="isDateShow" style="width:500px"></TreeselectCode>
                    </div>
                    <div style="display: flex">
                        <a-button class="margin_right10" type="primary" @click="queryClick">查询</a-button>
                        <a-button class="margin_right10" type="primary" @click="resetClick">重置</a-button>
                        <a-button v-if="isUsersAgentChange" class="margin_right10" type="primary" @click="channelClick">变更用户所在渠道</a-button>
                        <a-button v-if="isBatchList" type="primary" @click="changeRecordClick">查看变更记录</a-button>
                    </div>
                </div>
                <template v-if="isChannelShow">
                    <div class="box" style="box-shadow: 0px 0px 5px rgba(136,136,136,0.17);margin-bottom: 15px">
                        <a-card title="追踪结果" :bordered="true" style="width: 100%">
                            <p>本次按【用户当前归属-"{{ name }}"】查询，共有 {{ count }} 名用户，分布列表如下，如需变更用户所在渠道请按右边按钮进行批量操作。</p>
                        </a-card>
                    </div>
                    <div class="contant-box">
                        <a-table ref="table" :columns="columns" :data-source="tableList" :row-key="record => record.userName"  :loading="tableLoading" :pagination="false">
                            <span slot="source" slot-scope="source, record">
                                <span v-if="record.source == 1">APP_Android</span>
                                <span v-else-if="record.source == 2">APP_iOS</span>
                                <span v-else-if="record.source == 3">直播_视频号</span>
                                <span v-else-if="record.source == 4">直播_抖音</span>
                                <span v-else-if="record.source == 5">直播预约小程序</span>
                                <span v-else-if="record.source == 6">H5阅读大使推广二维码</span>
                                <span v-else-if="record.source == 7">H5大使推广二维码</span>
                                <span v-else-if="record.source == 8">人工操作</span>
                                <span v-else-if="record.source == 9">历史注册</span>
                                <span v-else-if="record.source == 99">其他</span>
                                <span v-else>--</span>
                            </span>
                        </a-table>
                    </div>
                    <MyPagination :count="count" :pageNo="pageNo" @showSizeChangeFn="showSizeChangeFn" v-show="!tableLoading"/>
                </template>
                <div v-else>
                    <a-empty description="请选择用户当前归属后查询" />
                </div>
            </div>
        </a-spin>

        <!--    点击锁定按钮弹出框    -->
        <a-modal v-model="visible" title="变更用户渠道归属" @ok="handleOk">
            <a-form-model v-if="visible" ref="ruleForm" :model="locking" :rules="rules" >
                <a-form-model-item ref="ascription" label="用户归属" prop="ascription">
                    <a-input v-model="locking.ascription" :disabled="true" placeholder="请输入用户归属" />
                </a-form-model-item>
                <a-form-model-item ref="number" label="变更数量" prop="number">
                    <a-input v-model="locking.number" :disabled="true" placeholder="请输入变更数量" />
                </a-form-model-item>
                <a-form-model-item ref="isLocking" label="目标归属渠道" prop="isLocking">
                    <TreeselectCode @treeDataChange="treeDataChangeval" :isDateShow="isDateShow"></TreeselectCode>
                    <span v-if="treeDataShow" style="color: #f5222d">请选择目标归属渠道</span>
                </a-form-model-item>
                <a-form-model-item ref="remarks" label="变更理由" prop="remarks">
                    <a-textarea :rows="4" v-model="locking.remarks" placeholder="请输入变更理由" :max-length="100" />
                </a-form-model-item>
            </a-form-model>
        </a-modal>

        <router-view/>
    </div>
</template>

<script>
import MyPagination from "@/components/pagination/MyPagination"
import {codeFn} from "@/utils/tools"
import TreeselectCode from "@/components/channel/treeselectCode"
import {UserListForCode, UsersAgentChange} from "@/request/api/userManage"

export default {
    components: {MyPagination,TreeselectCode},
    created() {
        if (codeFn("/adminv2/usercenter/usersAgentChange")) this.isUsersAgentChange = true;
        if (codeFn("/adminv2/usercenter/batchList")) this.isBatchList = true;
    },
    data() {
        return {
            isUsersAgentChange:false,
            isBatchList:false,

            visible:false,
            spinning: false,
            isChannelShow:false,
            treeDataShow:false,
            columns: [
                {
                    title: "用户姓名",
                    dataIndex: "nickName",
                    key: "nickName",
                },
                {
                    title: "用户手机",
                    dataIndex: "userName",
                    key: "userName",
                },
                {
                    title: "注册来源",
                    dataIndex: "source",
                    key: "source",
                    scopedSlots: {customRender: "source"},
                },
                {
                    title: "注册时间",
                    dataIndex: "createTime",
                    key: "createTime",
                },
            ],
            tableList: [],
            rules: {
                isLocking: [{ required: true, message: ""}],
                remarks: [{ required: true, message: "请输入变更理由", trigger: "blue" }],
            },
            tableLoading: false,

            pageNo: 1, // 当前页
            pageSize: 20, // 每页条数
            count: 0, // 列表的总条数

            locking:{
                number:'',
                ascription:'',
                remarks:'',
                currentChannel: ''
            },

            channel:'', // 当前渠道名称字母
            name:'', // 渠道名称
            code:'', // 用于回显
            isDateShow:true,
        };
    },
    mounted() {
    },
    watch: {
        $route(to) {
            if (to.path === "/userManage/attributedToList") {
                this.getTagList();
                this.channel ? this.code = this.channel : this.channel = ''
            }
        },
    },
    methods: {
        // 点击查看变更记录
        changeRecordClick(){
            this.$router.push(`/userManage/attributedToList/AttributedToDetails`);
        },

        // 点击变更用户所在渠道
        channelClick(){
            if(!this.channel){
                this.$message.warning('请选择用户当前归属')
                return false
            }
            this.visible = true
            this.locking.ascription = this.name
            this.locking.number = this.count
        },

        // 提交变更用户
        handleOk(){
            if(!this.locking.currentChannel){
                this.treeDataShow = true
                return false
            }else{
                this.treeDataShow = false
            }
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    UsersAgentChange({
                        oldCode:this.channel,
                        toCode:this.locking.currentChannel,
                        reason:this.locking.remarks,
                    }).then((res) => {
                        if (res.code === 200) {
                            this.$message.success('渠道变更成功！')
                            this.visible = false
                        }
                    });
                }
            });
        },

        // 获取弹出框渠道
        treeDataChangeval(val){
            this.locking.currentChannel = val
        },

        // 搜索·用户归属
        treeDataChange(data,name){
            this.channel = data
            this.name = name
            this.isDateShow = true
            this.isChannelShow = false
        },

        // 点击重置
        resetClick(){
            this.pageNo = 1;
            this.pageSize = this.pageSize;
            this.name = ''
            this.channel = ''
            this.code = ''
            this.isDateShow = false
            this.getTagList()
        },

        // 点击查询按钮
        queryClick(){
            if(!this.channel){
                this.$message.error('请选择完用户当前归属查询！')
                return false
            }else{
                this.pageNo = 1;
                this.pageSize = this.pageSize;
                this.getTagList();
            }
        },

        // 分页功能切换的回调
        showSizeChangeFn(current, pageSize) {
            this.pageNo = current;
            this.pageSize = pageSize;
            this.getTagList();
        },

        // 列表数据
        getTagList() {
            this.pageSize = this.pageSize
            this.isChannelShow = true
            this.tableLoading = true;
            UserListForCode({
                code: this.channel.toString(),
                pageNo: this.pageNo,
                pageSize: this.pageSize
            }).then((res) => {
                if (res.code === 200) {
                    this.tableList = res.data.list;
                    this.count = res.data.count;
                }
                this.tableLoading = false;
            });
        },

        // 回到顶部的函数
        targetFn() {
            return document.querySelector("#course");
        },
    }
};
</script>

<style lang="less" scoped>
/deep/.ant-empty{
    margin-top: 200px;
}
#course_category {
    height: 100%;
    overflow: hidden;
    overflow-y: scroll;
}

.head-box {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}

.avatar {
    width: 160px;
    height: 80px;
    object-fit: contain;
}

.btn-edit {
    margin-right: 10px;
}
.table-operator{
    display: flex;
    justify-content: flex-end;
    flex: 1;
}
/deep/.ant-table-wrapper{
    margin: 0 !important;
}
/deep/.ant-form-item-control{
    line-height: 2 !important;
}
</style>
